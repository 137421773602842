import { library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  faCheckCircle,
  faDownload,
  faEllipsisV,
  faPlus,
  faQuestionCircle,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTrash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "src/assets/App.scss";
import NewChannelMapping from "src/components/ChannelMappings/Create";
import ChannelMappings from "src/components/ChannelMappings/Index";
import ShowChannelMapping from "src/components/ChannelMappings/Show";
import CreateCompany from "src/components/Companies/Create";
import EditCompany from "src/components/Companies/Edit";
import Companies from "src/components/Companies/Index";
import ShowCompany from "src/components/Companies/Show";
import Dashboard from "src/components/Dashboard";
import Documentation from "src/components/Documentation";
import EpochLookup from "src/components/EpochLookup";
import NotFound from "src/components/NotFound";
import Profile from "src/components/Profile";
import Register from "src/components/Register";
import ReleaseNotes from "src/components/Regulatory/ReleaseNotes";
import SoftwareLabel from "src/components/Regulatory/SoftwareLabel";
import Reset from "src/components/Reset";
import Root from "src/components/Root";
import CreateSite from "src/components/Sites/Create";
import Sites from "src/components/Sites/Index";
import ShowSite from "src/components/Sites/Show";
import AttachChannelMapping from "src/components/Studies/AttachChannelMapping";
import NewStudy from "src/components/Studies/Create";
import StudiesIndex from "src/components/Studies/Index";
import OrpReport from "src/components/Studies/OrpReport";
import StudyDetails from "src/components/Studies/Show";
import UpdateScoringSettings from "src/components/Studies/UpdateScoringSettings";
import CreateUser from "src/components/Users/Create";
import EditUser from "src/components/Users/Edit";
import Users from "src/components/Users/Index";
import ShowUser from "src/components/Users/Show";
import Wizard from "src/components/Wizard";
import { UserProvider } from "src/UserContext";

// // fontawesome
// Important Note: if and when we need more icons, we need to add it to the library here first
// then we can use it through out the app
library.add(
  faCheckCircle,
  faDownload,
  faEllipsisV,
  faPlus,
  faQuestionCircle,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTrash,
  faUserCircle,
);

// initFontAwesome();
// add an error page, for your own sanity

const router = createBrowserRouter([
  {
    // add a nicer error page, be better Blaine - Blaine
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "/wizard", element: <Wizard /> },
      // Account Management
      { path: "/register", element: <Register /> },
      { path: "/reset", element: <Reset /> },
      // Users
      { path: "/users", element: <Users /> },
      { path: "/profile", element: <Profile /> },
      { path: "/newuser", element: <CreateUser /> },
      { path: "/user/:id", element: <ShowUser /> },
      { path: "edituser/:id", element: <EditUser /> },
      // Studies
      { path: "/studies", element: <StudiesIndex /> },
      { path: "/newstudy", element: <NewStudy /> },
      { path: "/study/:id", element: <StudyDetails /> },
      {
        path: "/updatescoringsettings/:id",
        element: <UpdateScoringSettings />,
      },
      { path: "/attachChannelMapping/:id", element: <AttachChannelMapping /> },
      { path: "/orpreport/:scoringRunId", element: <OrpReport /> },
      {
        path: "/orpreportdetailed/:scoringRunId",
        element: <OrpReport detailed />,
      },
      // Companies
      { path: "/companies", element: <Companies /> },
      { path: "/company/:id", element: <ShowCompany /> },
      { path: "/newcompany", element: <CreateCompany /> },
      { path: "/editCompany/:id", element: <EditCompany /> },
      // Sites
      { path: "/sites", element: <Sites /> },
      { path: "/site/:id", element: <ShowSite /> },
      { path: "/newsite", element: <CreateSite /> },
      // ChannelMappings
      { path: "/channelmappings", element: <ChannelMappings /> },
      { path: "/channelmapping/:id", element: <ShowChannelMapping /> },
      { path: "/newchannelmapping", element: <NewChannelMapping /> },
      { path: "*", element: <NotFound /> },
      // Regulatory
      { path: "/releasenotes", element: <ReleaseNotes /> },
      { path: "/softwarelabel", element: <SoftwareLabel /> },
      // TBD
      { path: "/docs", element: <Documentation /> },
      { path: "/uploadfile", element: <></> },
      { path: "/channelmapping", element: <></> },
      { path: "/epochlookup", element: <EpochLookup /> },
    ],
  },
]);

function App() {
  return (
    <UserProvider>
      <div className="app">
        <RouterProvider router={router} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
          theme="colored"
        />
      </div>
    </UserProvider>
  );
}

export default App;
